/*
 *   File : index.js
 *   Author : https://evoqueinnovativelab.com
 *   Description : index file for home container 
 *   Integrations : 29-12-2022
 *   Version : 1.0
 *  Created : 29-12-2022
 */
//import packages
import React, { useEffect, useState, useRef } from "react";
import { PrimaryBtn } from "../../components/cta";
// import { useNavigate } from "react-router-dom";

// import assets
import CRE from '../../assets/images/home/cre.svg';
import Startup from '../../assets/images/home/bar.svg';
import Lease from '../../assets/images/home/lease.svg';
import Calendar from '../../assets/images/home/calendar.svg';
import Thumbail from '../../assets/images/home/thumbnail.svg';
import SEBI from '../../assets/images/home/sebi.svg';
import Experience from '../../assets/images/home/experience.svg';
import Strategy from '../../assets/images/home/strategy.svg';



//import assets
import { DealCard, LeaseCard } from "../../components/card";
// import { Tab } from "../../components/formatting";


const Home = (props) => {
    // const navigator = useNavigate();
    const [propertyList, setPropertyList] = useState([])
    const scrollToRef = useRef();
    //eslint-disable-next-line
    const [index, setIndex] = useState(1)


    useEffect(() => {
        window.scrollTo({
            top: 0,
            behavior: 'smooth'
        })
    }, []);

    useEffect(() => {
        setPropertyList(props.list)
    }, [props.list]);

    useEffect(() => {
        if (props.ref) {
            scrollToRef.current.scrollIntoView({ behavior: "smooth" })
        }
    }, [props])

    const options = [
        {
            name: "Commercial RE",
            icon: CRE,
            color: "#E7F7EF",
            textColor: "#3CBC81"
        },
        {
            name: "Startup Investing",
            icon: Startup,
            color: "#ECE8FA",
            textColor: "#6741D9",
            coming: "Coming soon"
        },
        {
            name: "Lease financing",
            icon: Lease,
            color: "#E6F0FC",
            textColor: " #2F86E7",
            coming: "Coming soon"
        },
        {
            name: "Pre-IPO",
            icon: Calendar,
            color: "#FDECF2",
            textColor: "#f06292",
            coming: "Coming soon"
        },

    ]


    return (
        <>
            <div className="e-home-wrapper e-main-sec pt-0">

                {/* title */}
                <div className="row">
                    <div className="col-lg-8 col-md-12 col-12  text-start align-self-center">
                        <h1>Diversify your portfolio with alternatives</h1>
                        <p>Gain access to pre-vetted investment opportunities previously only available to the wealthiest 1%. Diversify your portfolio with non-market investments that offer attractive returns starting at 25L.</p>
                        <div className="col-12 text-start">
                            <PrimaryBtn
                                name='See investments'
                                handleClick={() => {
                                    scrollToRef.current.scrollIntoView({ behavior: "smooth" })
                                }} />
                        </div>

                    </div>
                    <div className="col-lg-4 col-md-12 col-12 offset-0 text-center">
                        <div className="row e-investment-card-sec">
                            {
                                options.map((item, key) => {
                                    return (
                                        <div className="col-lg-6 col-md-6 col-12" key={key}>
                                            <div className="e-investment-card" style={{ backgroundColor: item.color }}>
                                                <div className="text-center mb-3">
                                                    <img src={item.icon} alt="" className="text-center" />
                                                </div>
                                                <p style={{ color: item.textColor }} className="mb-2">{item.name}</p>
                                                <span style={{ opacity: "0.5", fontSize: "14px", }}>{item.coming}</span>
                                            </div>
                                        </div>
                                    )
                                })
                            }
                        </div>
                        {/* <p>Egestas venenatis eget hac tortor pulvinar risus sit ultrices. Pretium netus faucibus accumsan amet fermentum amet ut faucibus bibendum. </p> */}
                    </div>
                </div>

                {/*----------- investment options cards ----------- */}
                {/* <div className="row e-investment-card-sec">
                    {
                        options.map((item, key) => {
                            return (
                                <div className="col-lg-3 col-md-6 col-12" key={key}>
                                    <div className="e-investment-card" style={{ backgroundColor: item.color }}>
                                        <div className="text-center mb-3">
                                            <img src={item.icon} alt="" className="text-center" />
                                        </div>
                                        <p style={{ color: item.textColor }}>{item.name}</p>
                                    </div>
                                </div>
                            )
                        })
                    }
                </div> */}

                {/*---------- Management info sec ----------- */}
                <div className="container e-info-card">
                    <div className="row">
                        <div className="col-xl-4 col-md-3 col-12 text-xl-end text-md-start text-start">
                            <img src={Thumbail} alt="" className="e-thumbnail" />
                        </div>
                        <div className="col-xl-7 col-md-9 col-12 ps-lg-4 ps-md-5">
                            <h5>Managed By</h5>
                            <h4>{props.detail?.name}</h4>
                            <div className="e-info-section mt-5">
                                <div className="row">
                                    <div className="col-lg-4 col-md-6 col-12">
                                        <img src={SEBI} alt="" />
                                        <p className="pt-2 mb-0">SEBI Registered</p>
                                        <h6>{props.detail?.sebi_register_no}</h6>
                                    </div>
                                    <div className="col-lg-3 col-md-6 col-12">
                                        <img src={Experience} alt="" />
                                        <p className="pt-2 mb-0">Experience</p>
                                        <h6>{props.detail?.experience}</h6>
                                    </div>
                                    <div className="col-lg-5 col-md-6 col-12">
                                        <img src={Strategy} alt="" />
                                        <p className="pt-2 mb-0">Investment Strategy</p>
                                        <h6>{props.detail?.investment_strategy}</h6>
                                    </div>
                                </div>
                                <div className="row mt-4">
                                    <div className="col-lg-12">
                                        <div className="e-text" >
                                            {props.detail?.bio}
                                        </div>
                                        {/* <PrimaryLink
                                            name="Read more"
                                            className="e-read-more ps-0 mt-4"
                                            right={true}
                                            image={Down}
                                            iconClass="ms-3" /> */}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="container e-investment-section" ref={scrollToRef} id="e-investment">
                    <div className={"row px-md-5 mx-md-4 px-sm-0 mx-sm-0 px-0 mx-0 "}>
                        <div className="col-lg-12">
                            <h2>Investment Dashboard</h2>
                        </div>
                        {/* <div className="col-lg-12 justify-content-center d-flex mb-4">
                            <Tab
                                tabIndex={index}
                                setTab={(indexValue) => {
                                    setIndex(indexValue)
                                }}
                                data={[
                                    "Commercial Real Estate",
                                    "Lease Financing"
                                ]}
                            />
                        </div> */}
                        {
                            propertyList.map((item, key) => {
                                return (
                                    <>
                                        <div className="col-lg-6 mb-3  " key={key}>
                                            {
                                                index === 1 ?
                                                    <DealCard
                                                        // horizontal={true}
                                                        data={item.property}
                                                        getId={(id) => props.getId(id)}
                                                    />
                                                    :
                                                    <LeaseCard
                                                        // horizontal={true}
                                                        data={item.property}
                                                        getId={(id) => props.getId(id)}
                                                    />
                                            }
                                        </div>
                                    </>

                                )
                            })
                        }
                    </div>
                </div>

                {/*-------------- Investment section ------------*/}


            </div >
        </>
    )
}
export default Home;

