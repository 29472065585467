/*
 *   File : invest.js
 *   Author URI : https://evoqueinnovativelab.com
 *   Description : Invest Modal
 *   Integrations :
 *   Version : v1.0
 *   Created : 09-12-2021
 */

/*import packages*/
import React, { useEffect, } from 'react';
import $ from 'jquery'

/*import styles*/
import styles from '../../styles/components/success.module.scss';
import Close from '../../assets/images/modal/close.svg';


/*import assets */
import Success from '../../assets/images/modal/successGif.gif';



export default function SuccessModal(props) {

    useEffect(() => {
        var myModal = document.getElementById('success');
        myModal.addEventListener('shown.bs.modal', () => {
            setTimeout(() => {
                $('#close-success-btn').trigger('click');
            }, 2000)
        })
    }, []);

    return (
        <div className={`${styles.e_success_modal} modal fade`} id="success" data-bs-backdrop="static" data-bs-keyboard="false" tabIndex="-1" aria-labelledby="successLabel" aria-hidden="true" >
            <div className={`modal-dialog modal-dialog-centered ${styles.e_modal_dialog}`}>
                <div className={`${styles.e_content} modal-content position-relative`}>
                    <div className={`${styles.e_modal_header} text-center pt-4 pb-3`}>
                        <h5 className=''>Details submitted successfully</h5>
                        <img
                            id="close-success-btn"
                            alt="close"
                            src={Close}
                            className={`${styles.e_modal_close}`}
                            data-bs-dismiss="modal"

                        />
                    </div>
                    <div className='modal-body'>
                        <div className="container px-3">

                            <div className="row pb-5 mb-3">
                                <div className="col-lg-12 text-center">
                                    <img src={Success} alt="" className={`${styles.e_success_icon}`} style={{ marginTop: "40px" }} />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>


            </div>
        </div>

    )
}



