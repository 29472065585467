/*
 *   File : service.js
 *   Author : https://evoqueinnovativelab.com
 *   Description :helper function
 *   Integrations : null
 *   Version : 1.0
 *  Created : 29-12-2022
 */
import axios from 'axios';

export default async function services(is_post, url, data) {
    var API_URL = "";
    if (is_post) {

        API_URL = url
        const token = localStorage.getItem('access_token');
        return await axios.post(
            API_URL, data, {

            'headers': {
                'method': 'post',
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                Authorization: `Bearer ${token}`,
            }
        }

        ).then(function (response) {
            return response.data
        }).catch((error) => {
            if (error.response) {
                // The request was made and the server responded with a status code
                // that falls out of the range of 2xx
                if (error.response.data.status_code === 401) {
                    // localStorage.clear();
                    // sessionStorage.clear();
                    localStorage.removeItem("access_token");
                    // localStorage.removeItem("demo");
                    // localStorage.setItem("mode", true);
                    window.location = "/";
                }
                return error.response.data;
            } else if (error.request) {
                // The request was made but no response was received
                // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
                // http.ClientRequest in node.js
                console.log("error.request", error.request);
            }
        })
    }
    //eslint-disable-next-line
    var API_URL = url
    let token = localStorage.getItem('access_token');
    return await axios.get(
        API_URL,
        {
            headers: {
                'method': 'get',
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                Authorization: `Bearer ${token}`,
            }
        }).then(function (response) {
            return response.data
        })
        .catch((error) => {
            if (error.response) {
                // The request was made and the server responded with a status code
                // that falls out of the range of 2xx
                if (error.response.data.status_code === 401) {
                    // localStorage.clear();
                    // sessionStorage.clear();
                    sessionStorage.clear();
                    sessionStorage.removeItem("access_token");
                    localStorage.removeItem("access_token");
                    // localStorage.removeItem("demo");
                    // localStorage.setItem("mode", true);
                    window.location = "/";
                }
                return error.response.data;
            } else if (error.request) {
                // The request was made but no response was received
                // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
                // http.ClientRequest in node.js
            }
        });
} 