import React, { useState, useEffect, useRef } from 'react'
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";


import { Header } from './components/navigation'
import Home from './containers/home';
import Investment from './containers/investement';
import Detail from './containers/detail';
import { EnterDetail, SuccessModal } from './components/modal';
import Footer from './components/navigation/footer';

//import services
import services from "./services/service";
import devconfig from "./config/config";
import Loader from './components/loader/loader';
import { Modal } from 'bootstrap';

export default function App(props) {
    const [propertyList, setPropertyList] = useState([]);
    const [loader, setLoader] = useState(false);
    const [propertyId, setPropertyId] = useState(null);
    const [detail, setDetail] = useState({});

    const dataFetchedRef = useRef(false);


    useEffect(() => {
        if (dataFetchedRef.current) return;
        dataFetchedRef.current = true;
        getManagerDetails()
        //eslint-disable-next-line
    }, [])



    // /*--- list of properties *----/
    const getPropertyList = (id) => {

        let url = devconfig.url + "/property/list";

        let data = {
            "page_num": 1,
            "page_size": 100,
            "wealth_manager_id": id
        }

        services(true, url, data).then((response) => {
            if (response.status_code === 200) {
                setPropertyList(response.data)

            } else {
                setPropertyList([]);
            }
        })
    }


    const getManagerDetails = async () => {
        setLoader(true)
        let url = devconfig.url + "/general/get";

        let data = {
            url: window.location.origin
        }

        await services(true, url, data).then((response) => {
            if (response.status_code === 200) {
                setLoader(false);
                setDetail(response.data)
                getPropertyList(response.data.id);

                document.querySelector('title').textContent = response.data.meta.title;
                document.querySelector('link[rel="icon"]').setAttribute("href", response.data.meta.favicon);
                document.querySelector('link[rel="apple-touch-icon"]').setAttribute("href", response.data.meta.favicon);
                document.querySelector('meta[name="description"]').setAttribute("content", response.data.meta.description);
                var meta = document.createElement('meta');
                meta.name = 'keywords';
                meta.setAttribute('content', response.data.meta.keywords);
                document.getElementsByTagName('head')[0].appendChild(meta);

            } else {
                setLoader(false);
                // setPropertyList([]);
            }
        })
    }


    return (
        <>
            <Router>
                <Header detail={detail} />
                <div className='mt-5 mb-5 pb-5 '>
                    {
                        loader ?
                            <Loader height="250px" />
                            :
                            <Routes>
                                <Route exact path="/" element={<Home list={propertyList} getId={(id) => setPropertyId(id)} detail={detail} />} />
                                <Route exact path="/investment" element={<Investment list={propertyList} getId={(id) => setPropertyId(id)} />} />
                                <Route exact path="/detail" element={<Detail getId={(id) => setPropertyId(id)} />} />
                            </Routes>
                    }

                </div>
                <div className='mt-4'>
                    <Footer detail={detail} />
                </div>
            </Router>

            {/*------------ enquiry modal ------------*/}
            <EnterDetail
                success={() => {
                    var modal = new Modal(document.getElementById("success"), {});
                    modal.show()
                }}
                id={propertyId} />

            {/*------------ success modal ------------*/}
            <SuccessModal />

        </>
    )
}