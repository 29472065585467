/*
 *   File : addFaq.js
 *   Author URI : https://evoqueinnovativelab.com
 *   Description : Success Modal
 *   Integrations :
 *   Version : v1.0
 */

/*import packages*/
import React, { useState, useEffect } from 'react';
import $ from 'jquery';

/*import componets*/

import { PrimaryBtn } from '../cta/index';
import { Input, PhoneWithFlag } from '../form';

/* import assets */
import Close from '../../assets/images/modal/close.svg'

/*import styles*/
import styles from '../../styles/components/enter.module.scss';


//import services
import devconfig from '../../config/config';
import services from '../../services/service';
import Toast from '../note/toast';



export default function EnterDetail(props) {
    //eslint-disable-next-line
    const [loader, setLoader] = useState(false);
    const [phone, setPhone] = useState(null);
    const [fName, setFName] = useState("");
    const [lName, setLName] = useState("");
    const [email, setEmail] = useState("");
    const [error, setError] = useState(null);

    useEffect(() => {
        var myModal = document.getElementById('enter-detail');
        myModal.addEventListener('hidden.bs.modal', () => {
            setFName("");
            setLName("");
            setEmail("");
            setPhone(null);
        })
        //eslint-disable-next-line
    }, []);

    const enquiry = () => {
        setLoader(true)
        let url = devconfig.url + '/property/enquire'

        let data = {
            "fname": fName,
            "lname": lName,
            "email": email,
            "mobile": phone,
            "wealth_manager_id": 1,
            "property_id": props.id
        }

        services(true, url, data).then((response) => {
            if (response.status_code === 200) {
                setLoader(false);
                $("#close-btn").trigger("click");
                props.success()
            } else {
                console.log(response)
                setError(response.message);
                setLoader(false);
                setTimeout(() => {
                    setError(null)
                }, 3000);
            }
        })
    }


    return (
        <div className={`${styles.e_detail_modal} modal fade`} id="enter-detail" tabIndex="-1" aria-labelledby="success" aria-hidden="true">
            <div className="modal-dialog modal-dialog-centered">
                <div className={`modal-content ${styles.e_modal_content}`}>
                    <div className={`${styles.e_modal_header} text-center pt-4 pb-3`}>
                        <h5 className='mt-1'>Enter details</h5>
                        <img
                            src={Close}
                            className={`${styles.e_modal_close}`}
                            data-bs-dismiss="modal"
                            id="close-btn"
                            alt=""
                        />
                    </div>
                    <div className="container px-4">
                        <div className="row pt-3 ">
                            <div className="col-lg-12 col-12 ps-0 gx-0 ps-3 mt-2 text-center">
                                <span className={`${styles.e_sub_text} `}>Please enter your details, an expert from our team will get in touch with you.</span>
                            </div>
                        </div>
                        <div className='row pt-3'>
                            <div className='col-lg-6 mb-3'>
                                <label>First name</label>
                                <Input
                                    placeholder="First name"
                                    value={fName}
                                    valueChange={(value) => {
                                        setFName(value);
                                    }}
                                />
                            </div>
                            <div className='col-lg-6 mb-3'>
                                <label>Last name</label>
                                <Input
                                    placeholder="Last name"
                                    value={lName}
                                    valueChange={(value) => {
                                        setLName(value);

                                    }} />
                            </div>
                            <div className='col-lg-12 mb-3'>
                                <label>Last name</label>
                                <PhoneWithFlag
                                    id="phone-input"
                                    className="e-login-phone-input"
                                    value={phone}
                                    country={(val) => { }}
                                    // number={(val) => setNumber(val)}
                                    valueChange={(value) => {
                                        setPhone(value);

                                    }} />
                            </div>
                            <div className='col-lg-12 mb-3'>
                                <label>Email</label>
                                <Input
                                    type="email"
                                    value={email}
                                    valueChange={(value) => {
                                        setEmail(value);
                                    }} />
                            </div>
                        </div>
                        <div className="row pt-4 mt-2 pb-2">

                            <div className="col-lg-12 col-md-12 col-sm-12 mb-3">
                                {
                                    fName && lName && email && phone ?
                                        <PrimaryBtn
                                            name={loader ? "Sending " : "Send"}
                                            className={loader ? `w-100 e-btn-loader` : `w-100`}
                                            handleClick={() => {
                                                enquiry()
                                            }}
                                        />
                                        :
                                        <PrimaryBtn
                                            className={`e-disabled w-100`}
                                            name="Send"
                                        />
                                }
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {
                error ?
                    <Toast data={error} />
                    :
                    null
            }
        </div>
    )
}
