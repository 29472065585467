/*
 *   File : index.js
 *   Author : https://evoqueinnovativelab.com
 *   Description : index file for investment container 
 *   Integrations : null
 *   Version : 1.0
 *  Created : 29-12-2022
 */
//import packages
import React, { useEffect, useState } from "react";

// import assets

import { DealCard } from "../../components/card";

//import assets

//import services

const Investment = (props) => {
    const [propertyList, setPropertyList] = useState([])


    useEffect(() => {
        window.scrollTo({
            top: 0,
            behavior: 'smooth'
        })
    }, []);

    useEffect(() => {
        setPropertyList(props.list)
    }, [props.list]);


    return (
        <>
            <div className="e-investment-wrapper mt-5">
                {/*-------------- Investment section ------------*/}
                <div className="container e-investment-section mt-5">
                    <div className="row">
                        <div className="col-lg-12 mt-5 pt-4">
                            <h2>Investment Dashboard</h2>
                        </div>
                        {
                            propertyList.map((item, key) => {
                                return (
                                    <div className="col-lg-12 mb-3" key={key}>
                                        <DealCard
                                            horizontal={true}
                                            data={item.property}
                                            getId={(id) => props.getId(id)}
                                        />
                                    </div>
                                )
                            })
                        }
                    </div>
                </div>

            </div>
        </>
    )
}
export default Investment;

