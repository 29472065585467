/*
 *   File : index.js
 *   Author : https://evoqueinnovativelab.com
 *   Description : index file for detail container 
 *   Integrations : 29-12-2022
 *   Version : 1.0
 *  Created : 29-12-2022
 */
//import packages
import React, { useEffect, useState } from 'react';

//import asset
import Entry from '../../assets/images/detail/entry.svg';
import Asset from '../../assets/images/detail/asset.svg';
import SupplierIcon from '../../assets/images/detail/supplierIcon.svg'
import RightArrow from '../../assets/images/detail/arrow-right-black.svg';
import Marker from '../../assets/images/card/marker.svg'

// import component
import { PrimaryBtn, PrimaryLink } from "../../components/cta";
import CustomSlider from '../../components/slider/slider';
import Loader from '../../components/loader/loader';

//import services
import devconfig from '../../config/config';
import services from '../../services/service';
import GetURLVar from '../../services/getUrl';
import _getCurrentValue from '../../services/getCurrentValue'

const Detail = (props) => {

    const [loader, setLoader] = useState(false);
    const [detail, setDetail] = useState({});
    const [media, setMedia] = useState([]);

    useEffect(() => {
        window.scrollTo({
            top: 0,
            behavior: 'smooth'
        })
        getUrl()
        //eslint-disable-next-line
    }, [])


    const getUrl = async () => {
        const query = await GetURLVar()
        getPropertyDetail(query.id)
    }

    // /*--- detail of properties *----/
    const getPropertyDetail = (id) => {
        setLoader(true)
        let url = devconfig.url + "/property/detail";

        let data = {
            "re_property_id": Number(id)
        }

        services(true, url, data).then((response) => {
            if (response.status_code === 200) {
                setLoader(false);
                setDetail(response.data[0].property);
                let tempMediaArr = []
                tempMediaArr.push(response.data[0].property.image_url)
                setMedia(tempMediaArr)
            } else {
                setLoader(false);
                setDetail([]);
            }
        })
    }

    return (
        <div className="e-detail-sec mt-5 pt-5" >
            {
                loader ?
                    <Loader height="250px" />
                    :
                    <div className="container mt-5">

                        {/* Heading */}

                        <div className="row">
                            <div className="col-lg-12 d-flex">
                                <a className="e-breadcrumb" href="home">Commercial Real Estate</a>
                                <img src={RightArrow} alt="arrow" className="mx-1" width="12px" height="12px" />
                                <a className="e-breadcrumb" href="marketplace">Properties</a>
                                <img src={RightArrow} alt="arrow" className="mx-1" width="12px" height="12px" />
                                <PrimaryLink className="e-breadcrumb-active" activeClassName="" name="Details" />
                            </div>
                        </div>

                        <div className="row">
                            <div className="col-lg-12 pe-4">
                                <CustomSlider media={media} />
                            </div>
                            <div className="col-lg-12">
                                <h5 className="e-dashboard-greeting mt-3">
                                    {detail.property_name}
                                </h5>
                                <p className="e-location"><img src={Marker} alt="" onClick={() => {
                                    window.open(detail.location_url)
                                }} />
                                    {detail.location}</p>
                            </div>
                            <div className="row">
                                <h6 className='e-detail-title'>Filled</h6>
                                <div className="col-lg-6 e-detail-progress mb-3">
                                    <p className="e-amount">
                                        <span> ₹{_getCurrentValue(parseFloat((detail.filled_value?.split("₹")[1])?.replace(/,/g, '')))}</span> Funded of ₹{_getCurrentValue(parseFloat((detail.asset_value?.split("₹")[1])?.replace(/,/g, '')))}
                                    </p>
                                    <div className={` progress e-progress`} style={{ height: "28px" }}>
                                        <div className={`progress-bar e-progress-bar `} style={{ width: `${detail.filled}`, height: '28px' }} role="progressbar" aria-valuenow="20" aria-valuemin="0" aria-valuemax="100">
                                            <span className='text-end pe-2'>{detail.filled}</span>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-6 text-lg-end mb-3">
                                    <PrimaryBtn
                                        name="I'm interested"
                                        toggle="modal"
                                        target="#enter-detail"
                                        handleClick={() => {
                                            props.getId(detail.id)
                                        }} />
                                </div>
                            </div>
                        </div>

                        {/* Cards */}
                        <div className='row e-detail mt-lg-2 mt-sm-2 mt-0 pt-lg-3 pt-3'>
                            <h6 className='e-detail-title mb-3'>Deal highlights</h6>
                            <div className='col-lg-4 col-md-4 mb-3'>
                                <div className='e-detail-card p-4 '>
                                    <div className='col-6'>
                                        <img src={Entry} alt="" />
                                    </div>
                                    <div className='col-12 pt-3'>
                                        <p className='mb-2'>
                                            Entry yield
                                        </p>
                                        <h6 className='e-value'>{detail.entry_yield}</h6>
                                    </div>
                                </div>
                            </div>

                            <div className='col-lg-4 col-md-4 mb-3'>
                                <div className='e-detail-card p-4 '>
                                    <div className='col-6'>
                                        <img src={Asset} alt="" />
                                    </div>
                                    <div className='col-12 pt-3'>
                                        <p className='mb-2'>
                                            Asset value
                                        </p>
                                        <h6 className='e-value'>₹ {_getCurrentValue(parseFloat((detail.asset_value?.split("₹")[1])?.replace(/,/g, '')))}</h6>
                                    </div>
                                </div>
                            </div>
                            <div className='col-lg-4 col-md-4 mb-3'>
                                <div className='e-detail-card p-4 '>
                                    <div className='col-6'>
                                        <img src={SupplierIcon} alt="" />
                                    </div>
                                    <div className='col-12 pt-3'>
                                        <p className='mb-2'>
                                            Supplier
                                        </p>
                                        <img src={detail.supplier_image} alt="" className="mt-2" width="60px" />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className='row pt-3'>
                            <h6 className='e-detail-title mb-3'>Overview</h6>
                            <p>{detail.overview}</p>
                        </div>
                        <div className='row pt-3'>
                            <h6 className='e-detail-title mb-3'>Tenant</h6>
                            <p>{detail.tenant}</p>
                        </div>
                        <div className='row pt-3'>
                            <h6 className='e-detail-title mb-3'>Location</h6>
                            <p>{detail.location}</p>
                            <iframe
                                src={detail.location_url}
                                width="800"
                                height="600"
                                allowfullscreen=""
                                loading="lazy"
                                referrerpolicy="no-referrer-when-downgrade">
                            </iframe>
                        </div>
                    </div>
            }

        </div>
    )
}

export default Detail;