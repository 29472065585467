/*
 *   File : header.js
 *   Author : https://evoqueinnovativelab.com
 *   Description : Header
 *   Integrations : null
 *   Version : 1.0.0
 *   Created : 29-12-2022
 */

/*import packages*/
import React from 'react';
import { Link, useNavigate } from 'react-router-dom';

/*import components */
import { PrimaryBtn } from '../cta';

/* import assets */
import Logo from '../../assets/images/header/logo.svg';

/*import styles*/
import styles from '../../styles/components/header.module.scss';


const Header = (props) => {
    const navigate = useNavigate()
    return (
        <>

            <div className={` ${styles.e_header} e-header`}>
                <div className="row">

                    <div className="col-lg-6 col-md-6 col-sm-6 col-6">
                        {/* <NavLink to='/login'> */}
                        <img
                            src={props.detail?.logo ? props.detail?.logo : Logo}
                            alt="Logo"
                            className={`${styles.e_logo}`}
                            onClick={() => {
                                navigate("/")
                            }} />
                        {/* </NavLink> */}
                    </div>
                    <div className="col-lg-6 col-md-6 col-sm-6 col-6 text-end pe-0">
                        {/* <Link to="/investment"> */}
                        <PrimaryBtn
                            name="See investments"
                            className="e-p-btn"
                            handleClick={() => {
                                navigate("/")
                                setTimeout(() => {
                                    const ele = document.getElementById("e-investment");
                                    ele.scrollIntoView({
                                        behavior: "smooth",
                                        block: "end",
                                        inline: "nearest"
                                    });
                                }, 200)
                            }} />
                        {/* </Link> */}
                    </div>
                </div>
            </div>
        </>
    )
}

export default Header;

