/*
 *   File : primaryLinkText.js
 *   Author : https://evoqueinnovativelab.com
 *   Description : Primary Link Text button
 *   Integrations : null
 *   Version : 1.0.0
 *   Created : 29-12-2022
 */

/*Style Import*/
import styles from '../../styles/components/primaryLinkText.module.scss';

const PrimaryLink = (props) => {
  return (
    <button
      id={props.id}
      className={`${styles.e_primary_link} ${props.className}`}
      data-bs-dismiss={props.cancel}
      data-bs-toggle={props.toggle}
      data-bs-target={props.target}
      onClick={props.handleClick}
      disabled={props.disabled}
      type={props.type}
    >
      {
        props.left ?
          <img src={props.image} className={props.iconClass} alt="" />
          :
          null
      }
      <span>
        {props.name}
      </span>
      {
        props.right ?
          <img src={props.image} className={props.iconClass} style={{ marginTop: props.marginTop }} alt="" />
          :
          null
      }

    </button>
  );
}


export default PrimaryLink;
