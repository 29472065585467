/*
 *   File : card .js
 *   Author : https://evoqueinnovativelab.com
 *   Description : C Card
 *   Integrations : null
 *   Version : 1.0.0
 * Created : 29-12-2022
*/

import { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { Modal } from 'bootstrap';

/*import styles*/
import styles from '../../styles/components/dealCard.module.scss';


/* import assets */
import Marker from '../../assets/images/card/marker.svg'

import { PrimaryBtn, PrimaryLink, PublishBtn } from '../cta';
import _getCurrentValue from '../../services/getCurrentValue';



const DealCard = (props) => {
    const navigate = useNavigate()
    //eslint-disable-next-line
    const [add, setAdd] = useState(false);
    const [error, setError] = useState();
    //eslint-disable-next-line
    const [added, setAdded] = useState(props.inBasket)

    useEffect(() => {
        if (add) {

            var modal = new Modal(document.getElementById("paymentSucess"))
            modal.show()
        }

    }, [add]);

    useEffect(() => {
        if (error !== null) {
            setTimeout(() => {
                setError(null)
            }, 10000)
        }
    }, [error])




    return (
        <>
            {
                props.horizontal ?
                    <div className={`${styles.e_deal_card} card border-0`} id={props.id}>
                        <div className="row">
                            <div className="col-lg-5">
                                <img src={props.data.image_url} alt="" className={styles.e_thumbnail} style={{ height: "100%", borderRadius: "14px" }} />
                            </div>
                            <div className='col-lg-7'>
                                <div className='row'>
                                    <div className='col-lg-12'>
                                        <h5 className={styles.e_title}>{props.data.property_name}</h5>
                                        <p className={styles.e_location}>
                                            <img src={Marker} alt="" className={styles.e_marker} onClick={() => {
                                                window.open(props.data.location_url)
                                            }} />
                                            {props.data.location}</p>
                                    </div>
                                    {
                                        props.detail ?
                                            null
                                            :
                                            <div className='row mt-2'>
                                                <div className='col-lg-6'>
                                                    <h6 className={styles.e_content}>Entry yield</h6>
                                                    <p className={styles.e_content_info}>{props.data.entry_yield}</p>
                                                </div>

                                                <div className='col-lg-6 '>
                                                    <h6 className={`text-end ${styles.e_content}`}>Asset value</h6>
                                                    <p className={`text-end ${styles.e_content_info}`}>₹ {_getCurrentValue(parseFloat((props.data.asset_value?.split("₹")[1])?.replace(/,/g, '')))}</p>
                                                </div>
                                            </div>
                                    }
                                </div>
                                {/* <div className='col-lg-12'>
                                    <h6 className={styles.e_content}>Tenate name</h6>
                                    <p className={styles.e_content_info}>Tenant-I - Century old international manufacturer
                                        Tenant - 2 - Indian manufacturer</p>
                                </div> */}
                                <div className='row mb-2'>
                                    <div className='col-lg-5'>
                                        <h6 className={styles.e_content}>Supplier</h6>
                                        <img src={props.data.supplier_image} alt="" width="50px" />
                                    </div>
                                    <div className='col-lg-7'>
                                        {
                                            props.detail ?
                                                <>
                                                    <h6 className={styles.e_content}>Commission</h6>
                                                    <p className={styles.e_content_info}>{props.data.commission}</p>
                                                </>
                                                :
                                                <>
                                                    <h6 className={styles.e_content}>Filled</h6>
                                                    <div className={`${styles.e_progress} progress e-progress`} >
                                                        <div className={`${styles.e_progress_bar} progress-bar e-progress-bar `} style={{ width: `${props.data.filled}`, height: '20px' }} role="progressbar" aria-valuenow="20" aria-valuemin="0" aria-valuemax="100">
                                                            <span className='text-end pe-2'>{props.data.filled}</span>
                                                        </div>

                                                    </div>
                                                </>
                                        }
                                    </div>
                                </div>
                                {
                                    props.fromBasket ?
                                        null
                                        :

                                        added ?
                                            <PublishBtn className="mt-3" />
                                            :
                                            <div className='col-lg-12'>
                                                <PrimaryBtn
                                                    name="I'm interested"

                                                    left={true}
                                                    iconClass="me-3"
                                                    className={styles.e_add_btn}
                                                    toggle="modal"
                                                    target="#enter-detail"
                                                    handleClick={() => {
                                                        props.getId(props.data.id)
                                                    }}
                                                />

                                            </div>
                                }
                                {
                                    props.fromBasket ?
                                        null
                                        :
                                        <div className='col-lg-12 mt-3 text-center'>
                                            <PrimaryLink
                                                name="View details"
                                                className={styles.e_view}
                                                handleClick={() => {
                                                    navigate("/detail?id=" + props.data.id)
                                                }
                                                } />
                                        </div>
                                }
                            </div>
                        </div>


                    </div>
                    :
                    <div className={`${styles.e_deal_card} card border-0`} id={props.id}>
                        <div className="row">
                            <div className="col-lg-12">
                                <img src={props.data.image_url} alt="" className={styles.e_thumbnail} />
                            </div>
                            <div className='col-lg-12'>
                                <h5 className={styles.e_title}>{props.data.property_name}</h5>
                                <p className={styles.e_location}>
                                    <img src={Marker} alt="" className={styles.e_marker} onClick={() => {
                                        window.open(props.data.location)
                                    }} />
                                    {props.data.location}</p>
                            </div>

                        </div>
                        <div className='row mt-2'>
                            <div className='col-lg-6'>
                                <h6 className={styles.e_content}>Entry yield</h6>
                                <p className={styles.e_content_info}>{props.data.entry_yield}</p>
                            </div>

                            <div className='col-lg-6 '>
                                <h6 className={`text-end ${styles.e_content}`}>Asset value</h6>
                                <p className={`text-end ${styles.e_content_info}`}>₹ {_getCurrentValue(parseFloat((props.data.asset_value?.split("₹")[1])?.replace(/,/g, '')))}</p>
                            </div>
                        </div>
                        {/* <div className='col-lg-12'>
                            <h6 className={styles.e_content}>Tenant name</h6>
                            <p className={styles.e_content_info}>{props.data.tenant ? props.data.tenant : '--'}</p>
                        </div> */}
                        <div className='row mb-2'>
                            <div className='col-lg-5'>
                                <h6 className={styles.e_content}>Supplier</h6>
                                <img src={props.data.supplier_image} alt="" width="50px" />
                            </div>
                            <div className='col-lg-7 text-end'>
                                <h6 className={`text-end ${styles.e_content}`}>Filled</h6>
                                <div className={`${styles.e_progress} progress e-progress`} >
                                    <div className={`${styles.e_progress_bar} progress-bar e-progress-bar `} style={{ width: `${props.data.filled}`, height: '20px' }} role="progressbar" aria-valuenow="20" aria-valuemin="0" aria-valuemax="100">
                                        <span className='text-end pe-2'>{props.data.filled}</span>
                                    </div>

                                </div>
                            </div>
                        </div>
                        {
                            props.fromPublish ?
                                <>

                                    <div className='col-lg-12 mt-auto'>
                                        <PrimaryBtn
                                            name="View details"
                                            // image={AddToBasket}
                                            // left={true}
                                            iconClass="me-3"
                                            className={styles.e_add_btn}
                                            handleClick={() => {
                                                navigate("/detail?id=" + props.data.id)
                                            }}
                                        />

                                    </div>

                                    <div className='col-lg-12 mt-3 text-center'>
                                        <PrimaryLink
                                            name="Unpublish"
                                            className={styles.e_unpublish} />
                                    </div>
                                </>
                                :
                                <>
                                    {
                                        added ?
                                            <PublishBtn className="mt-auto" />
                                            :
                                            <div className='col-lg-12 mt-auto'>
                                                <PrimaryBtn
                                                    name="I'm interested"
                                                    left={true}
                                                    iconClass="me-3"
                                                    className={styles.e_add_btn}
                                                    toggle="modal"
                                                    target="#enter-detail"
                                                    handleClick={() => {
                                                        props.getId(props.data.id)
                                                    }}
                                                />

                                            </div>
                                    }
                                    <div className='col-lg-12 mt-3 text-center'>
                                        <PrimaryLink
                                            name="View details"
                                            handleClick={() => {
                                                navigate("/detail?id=" + props.data.id)
                                            }}
                                            className={styles.e_view} />
                                    </div>
                                </>
                        }

                    </div>
            }
            {/* {
                add ?
                    <SuccessPayment
                        editScore={false}
                        // assetAdded={assetAdded}
                        heading={"Added to basket Successfully"}//type
                        status={true}//status

                        from="portfolio-tracker"
                        close={() => {
                            setAdd(false);
                            props.added(true)

                        }}
                    />
                    :
                    null
            } */}

            {/* {
                error && (
                    <Toast data={error} />
                )
            } */}
        </>
    )
}

export default DealCard;
