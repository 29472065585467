/*
 *   File : primary.js
 *   Author : https://evoqueinnovativelab.com
 *   Description : Primary Button
 *   Integrations : null
 *   Version : 1.0.0
 *   Created : 29-12-2022
 */

/*import style*/

import styles from '../../styles/components/primary.module.scss';


const PublishBtn = (props) => {
    // useEffect(()=>{

    // },[])
    return (
        <button id={props.id}
            className={props.disabled ? `e-input-wrap ${styles.e_publish_btn} ${styles.e_disabled} ${props.className}` : `e-input-wrap ${styles.e_publish_btn} ${props.className}`}
            data-bs-dismiss={props.cancel}
            data-bs-toggle={props.toggle}
            data-bs-target={props.target}
            onClick={props.handleClick}
            disabled={props.disable}
            type={props.type ? props.type : "submit"}
        >

            {/* <img src={Check} className="me-3" style={{ marginTop: props.marginTop }} /> */}

            <span>Ready to publish</span>
            {
                props.right && (
                    <img src={props.image} className={props.iconClass} alt="" />
                )
            }
        </button>
    );
}

export default PublishBtn;