/*
 *   File : footer.js
 *   Author : https://evoqueinnovativelab.com
 *   Description : footer component
 *   Integrations : null
 *   Version : 1.0
 *  Created : 29-12-2022
 */
import React from "react";
import { Link } from "react-router-dom";

//styles
import styles from '../../styles/components/footer.module.scss'

//assets
import Logo from '../../assets/images/header/logo.svg'

export default function Footer(props) {
    return (
        <div className={`${styles.e_footer} row e-footer`}>
            <div className="col-lg-12 text-center">
                <Link to="/">
                    <img src={props.detail?.logo ? props.detail?.logo : Logo} alt="" className={styles.e_logo} />
                </Link>
                <p>
                    {props.detail?.footer_note}
                </p>
                <a href="https://www.jamawealth.com" target="_blank" rel="noreferrer">
                    {props.detail?.website}
                </a>
            </div>
        </div>
    )
}