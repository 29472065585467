/*
 *   File : input.js
 *   Author : https://evoqueinnovativelab.com
 *   Description : Input box
 *   Integrations : null
 *   Version : 1.0.0
 *   Created : 29-12-2022
 */

/*import package*/
import { useState, useEffect, useRef } from 'react';
import $ from 'jquery'

/*import components */
// import { Toast } from '../../components/note/index';

/*import style*/
import styles from '../../styles/components/input.module.scss';

const Input = (props) => {

  const searchInput = useRef(null);

  const [value, setValue] = useState();
  //eslint-disable-next-line
  const [error, setError] = useState();


  useEffect(() => {
    if (props.defaultValue) {
      setValue(props.defaultValue);
    }
  }, [props.defaultValue])



  // useEffect(()=>{
  //   var element = document.getElementById('e-input-id');
  //   setTimeout(() => {
  //     console.log(props.value)
  //     console.log(element)
  //   }, 3000);

  //   // if(props.value ){

  //   //   alert('hai')
  //   //   element.classList.add('e-input-focus')
  //   // }
  // },[props.value])

  const validate = () => {
    if (props.type === "email" && value && value.length) {
      //eslint-disable-next-line
      if (!/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,})+$/.test(value)) {
        setError("Invalid email")
        props.valueChange(null)
        // setTimeout(()=>{ setError(null)},2000)
      }
    }
    // if (props.type === "text" && value && value.length) {
    //   if (!/^[a-zA-Z\s.-]+$/.test(value)) {
    //     setError("Invalid name")
    //     props.valueChange("")
    //     // setTimeout(()=>{ setError(null)},2000)
    //   }
    // }
    else if (props.type === "number" && (props.placeholder === "Phone Number" || props.placeholder === "Phone number" || props.placeholder === "Mobile Number")) {
      if (!/^\(?(\d{3})\)?[- ]?(\d{3})[- ]?(\d{4})$/.test(value)) {
        setError("Invalid phone")
      }
    }
    if (props.validateLength) {
      if (props?.minLength && (value?.length < props?.minLength)) {
        setError(`Minimum ${props.minLength} charcter${props?.minLength > 1 ? "s" : ""} required for ${props.name}`);
        props?.valueChange(null);
      }
      else if (value?.length > props?.maxLength) {
        setError(`Maximum charcter limit(${props.maxLength}) exceeded.`);
        props?.valueChange(null);
      }
    }
    if (props.onlyNumber) {
      if (!/^\d+$/.test(value)) {
        setError("Enter a valid number");
        props?.valueChange(null);
      }
    }
    setTimeout(() => {
      setError(null)
    }, 5000)
  }

  const valueChange = (e) => {
    console.log(e.target.value)
    if (props.isCapital) {
      setValue(e.target.value.toUpperCase());
      setError(null);
      props?.valueChange(e.target.value.toUpperCase(), e);
    } else {
      if (props.notDecimal) {
        console.log(e.target.value);
        if (e.target.value % 1 !== 0) {
          setError('Decimal not allowed');
        }
        else {
          setValue(e.target.value);
          setError(null);
          props?.valueChange(e.target.value, e);
        }
      } else {
        setValue(e.target.value);
        setError(null);
        props?.valueChange(e.target.value, e);
      }
    }

  }

  var invalidChars = [
    "-",
    "+",
    "e",
  ];

  const keyPress = (e) => {
    if (props.type === "number") {
      if (invalidChars.includes(e.key)) {
        e.preventDefault();
      }
    }
    if (e.keyCode === 13) {
      if (props.onSearch) {
        props?.onSearch(e)
      }
    }
    if (props.otp) props.keyCode(e.keyCode);
  }

  function focus() {
    searchInput.current.focus();


  }
  $(`#${props.id}`).on('keydown', function (e) {
    if (e.which === 32 && e.target.selectionStart <= 1) {
      return false;
    }
  });

  return (
    <div className={styles.e_input_wrap_old}>
      <input
        value={props.value}
        type={props.type}
        // className={error ?
        // `${styles.e_input_error} ${styles.e_input_box }`+" "+props.className : `${styles.e_input_box}` +" "+props.className}
        className={props.value ? `${styles.e_input_label} ${styles.e_input_box} e-input ${props.className}` : `${styles.e_input_box} e-input ${props.className}`}
        onChange={(e) => { valueChange(e) }}
        placeholder={props.placeholder}
        required={props.required}
        onBlur={() => { validate(); if (props.needBlurEvent) { props.focusOut(value); } }}
        onFocus={() => { if (props.needFocusEvent) { props.onFocus(); } setValue(props.value); }}
        prefix={props.prefix}
        onKeyDown={e => keyPress(e)}
        id={`${props.id ? props.id : props.inputId} e-input-id`}
        ref={searchInput}
        name={props.name}
        onWheel={() => document.activeElement.blur()}
        disabled={props.readOnly}
      // {...props}
      />

      {
        props.suffix &&
        <span className={styles.e_suffix}>{props.suffix}</span>
      }
      {props.label ? <label htmlFor={props.id} className="label" onClick={() => { focus(); }}>{props.label}</label> : null}
      {/* {
        error ?
          // <p className={styles.e_error_msg+" mb-0"}>{error}</p>
          <Toast data={error} />
          :
          null
      } */}
    </div>
  );
}
export default Input;

