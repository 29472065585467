/*
 *   File : loader.js
 *   Author URI : https://evoqueinnovativelab.com
 *   Description : Loader
 *   Integrations :
 *   Version : v1.0
 *   Created : 29-12-2022
 */

/*Import Packages*/
import React from 'react';

/*import style*/
import styles from '../../styles/components/loader.module.scss';
import PageLoader from '../../assets/images/loader/loader.gif';
import PrimaryLink from '../cta/primaryLinkText';

export default function Loader(props) {
	return (
		<div className={`${styles.e_loader} ${props.className} col-lg-12  text-center`} >
			<div className={styles.e_img_wrapper}>
				<img src={PageLoader} alt="" style={{ marginTop: props.height }} />
			</div>
			{
				props.removeTag ?
					null
					:
					<PrimaryLink className={`${styles.e_loader_text}  e-btn-loader`} name="Loading your data" />
			}

		</div>
	)
}
