/*
 *   File : config.js
 *   Author : https://evoqueinnovativelab.com
 *   Description : Config file for the template
 *   Version : 1.0.0
 *   Created : 29-12-2022
 */


const url = process.env.REACT_APP_DEV_API_URL

var devconfig = {
    url: url + 'client',
}

export default devconfig;