/*
 *   File : phoneWithFlag.js
 *   Author URI : https://evoqueinnovativelab.com
 *   Description : phoneWithFlag.js
 *   Integrations : 
 *   Version : v1.0
 */

/*Package importing*/
import React from 'react';
import 'react-phone-number-input/style.css'
import PhoneInput, { parsePhoneNumber } from 'react-phone-number-input'

/*import components */


/*import style*/
import styles from '../../styles/components/loginPhone.module.scss';

export default class PhoneWithFlag extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      value: "",
      error: null,
    }
  }

  componentDidMount() {
    if (this.props.defaultValue) {
      this.setState({ value: this?.props?.defaultValue });

    }
  }
  validate() {
    //eslint-disable-next-line
    if (this?.state?.value && this?.state?.value?.length < 9 || this?.state?.value?.length > 15 || this?.state?.value === "0000000000") {
      this.setState({ error: "Invalid phone" })
    }
  }
  valueChange(value) {
    this.setState({ value: value, error: null });
    this.props.valueChange(value);
    if (value !== undefined) {
      let val = parsePhoneNumber(value);
      if (this.props.country && this.props.number) {
        this.props.country(val?.countryCallingCode)
        this.props.number(val?.nationalNumber)
      }

    }
  }

  render() {

    return (
      <div className={this.props.isDisabled ? `${styles.e_phone_wrap} ${styles.e_input_disabled}` : styles.e_phone_wrap}>
        <PhoneInput
          id={this.props.id}
          placeholder="Enter phone number"
          autoPlaceholder="polite"
          initialCountry="in"
          value={this.props.value}
          onChange={(value, event) => {

            this.valueChange(value)

          }}
          //eslint-disable-next-line
          className={`${this.props.errorClass} ${styles.e_input_box}` + " " + this.props.className}
          defaultCountry="IN"
          onBlur={() => { this.props.callBack(this.state.value) }}
          onFocus={() => { if (this.props.needFocus) this.props.focus() }}
          international
          countryCallingCodeEditable={false}
          disabled={this.props.disabled}

        />
        <span className={`${styles.e_input_text} `}
          style={{ color: this.props.messageType === 'success' ? 'rgb(0, 121, 0)' : this.props.messageType === 'fail' ? 'rgb(231, 74, 59)' : null }}
        >{this.props.message}</span>
      </div>
    );
  }
}

