/*
 *   File : primary.js
 *   Author : https://evoqueinnovativelab.com
 *   Description : Primary Button
 *   Integrations : null
 *   Version : 1.0.0
 *  Created : 29-12-2022
 */

/*import style*/
import React from 'react';
import styles from '../../styles/components/primary.module.scss';


const PrimaryBtn = (props) => {
	return (
		<button id={props.id}
			className={props.disabled ? `e-input-wrap ${styles.e_p_btn} ${styles.e_disabled} ${props.className}` : `e-input-wrap ${styles.e_p_btn} ${props.className}`}
			data-bs-dismiss={props.cancel}
			data-bs-toggle={props.toggle}
			data-bs-target={props.target}
			onClick={props.handleClick}
			disabled={props.disable}
			type={props.type ? props.type : "submit"}
		>
			{
				props.left && (
					<img src={props.image} className={props.iconClass} style={{ marginTop: props.marginTop }} alt="" />
				)
			}
			<span>{props.name}</span>
			{
				props.right && (
					<img src={props.image} className={props.iconClass} alt="" />
				)
			}
		</button>
	);
}

export default PrimaryBtn;