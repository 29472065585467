
/*
 *   File : index.js
 *   Author : https://evoqueinnovativelab.com
 *   Description : slider
 *   Integrations : null
 *   Version : 1.0
 *  Created : 29-12-2022
 */

/* import packages */
import React, { Component } from "react";
import Slider from "react-slick";


// Import css files
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

// import assets
import larrow from '../../assets/images/cta/down.svg';
import rarrow from '../../assets/images/cta/down.svg';


function SampleNextArrow(props) {
    const { className, style, onClick } = props;
    return (
        <div
            className={className}
            style={{ ...style, display: "block", backgroundImage: `url(${rarrow})`, backgroundPosition: "50% 50%", right: "20px", borderRadius: "50px", width: "24px", height: "25px" }}
            onClick={onClick}
        />
    );
}

function SamplePrevArrow(props) {
    const { className, style, onClick } = props;
    return (
        <div
            className={className}
            style={{ ...style, display: "block", backgroundImage: `url(${larrow})`, backgroundPosition: "50% 50%", left: "20px", borderRadius: "50px", width: "24px", height: "25px", zIndex: "10" }}
            onClick={onClick}
        />
    );
}


export default class CustomSlider extends Component {
    constructor(props) {
        super(props);
        this.state = {
            nav1: null,
            nav2: null,
            isOpen: false,
            play: true,
            isModalOpen: false,
        };
    }

    componentDidMount() {
        this.setState({
            nav1: this.slider1,
            nav2: this.slider2
        });
    }
    pauseVideo = () => {
        this.setState({ play: false });
    }
    render() {
        const settings = {
            nextArrow: <SampleNextArrow />,
            prevArrow: <SamplePrevArrow />
        }

        return (
            <React.Fragment>
                <div className="e-slick-nav">
                    <Slider {...settings}
                        arrows={false}
                        autoplay={false}
                        autoplaySpeed={4000}
                        asNavFor={this.state.nav2}
                        ref={slider => (this.slider1 = slider)}>
                        {
                            this.props.media.map((item, index) => {
                                return (
                                    <div className="mt-3" key={index}>
                                        <img className="e-slidnav-img" src={item} alt="slider-thumb" />
                                    </div>
                                );
                            })
                        }
                    </Slider>
                    <Slider className="mt-4 d-flex e-slider-bottom"
                        {...settings}
                        asNavFor={this.state.nav1}
                        ref={slider => (this.slider2 = slider)}
                        slidesToShow={this.props.media.length}
                        swipeToSlide={true}
                        focusOnSelect={true}
                    >
                        {
                            this.props.media.map((item, index) => {

                                return (
                                    <div>
                                        <img className=" e-img-thum" src={item} alt="slider-thumb" />
                                    </div>
                                );
                            })
                        }
                    </Slider>

                </div>



            </React.Fragment>

        );
    }
}