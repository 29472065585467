/*
 *   File : card .js
 *   Author : https://evoqueinnovativelab.com
 *   Description : C Card
 *   Integrations : null
 *   Version : 1.0.0
 *   Created : 20-12-2021
*/

import { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

/*import styles*/
import styles from '../../styles/components/dealCard.module.scss';


/* import assets */
import Marker from '../../assets/images/card/marker.svg'

import { PrimaryBtn, PrimaryLink, PublishBtn } from '../cta';
import _getCurrentValue from '../../services/getCurrentValue';
import { Modal } from 'bootstrap';

const LeaseCard = (props) => {
    const navigate = useNavigate()
    //eslint-disable-next-line
    const [add, setAdd] = useState(false);
    const [error, setError] = useState();
    //eslint-disable-next-line
    const [added, setAdded] = useState(props.inBasket);


    useEffect(() => {
        if (add) {

            var modal = new Modal(document.getElementById("paymentSucess"))
            modal.show()
        }

    }, [add]);


    useEffect(() => {
        if (error !== null) {
            setTimeout(() => {
                setError(null)
            }, 10000)
        }
    }, [error])




    return (
        <>
            {
                <div className={`${styles.e_deal_card} card border-0`} id={props.id}>
                    <div className="row">
                        <div className="col-lg-12">
                            <img src={props.data.image_url} alt="" className={styles.e_thumbnail} />
                        </div>
                        <div className='col-lg-12'>
                            <h5 className={styles.e_title}>{props.data.property_name}</h5>
                            <p className={styles.e_location}>
                                <img src={Marker} alt="" className={styles.e_marker} onClick={() => {
                                    window.open(props.data.location_url)
                                }} />
                                {props.data.location}</p>
                        </div>
                        <div className='row mt-2'>
                            <div className='col-lg-4'>
                                <h6 className={styles.e_content}>Expected IRR</h6>
                                <p className={styles.e_content_info}>19-20%</p>
                            </div>
                            <div className='col-lg-4'>
                                <h6 className={styles.e_content}>Commission</h6>
                                <p className={styles.e_content_info}>{props.data.commission}</p>
                            </div>
                            <div className='col-lg-4'>
                                <h6 className={styles.e_content}>Tenure</h6>
                                <p className={styles.e_content_info}>30 months</p>
                            </div>
                        </div>
                        <div className='row mt-2'>
                            <div className='col-lg-4'>
                                <h6 className={styles.e_content}>Min Investement</h6>
                                <p className={styles.e_content_info}>₹ {_getCurrentValue(parseFloat((props.data.asset_value?.split("₹")[1])?.replace(/,/g, '')))}</p>
                            </div>
                            <div className='col-lg-4'>
                                <h6 className={styles.e_content}>Total units</h6>
                                <p className={styles.e_content_info}>29</p>
                            </div>
                            <div className='col-lg-4'>
                                <h6 className={styles.e_content}>Units sold</h6>
                                <p className={styles.e_content_info}>13</p>
                            </div>
                        </div>
                    </div>
                    <div className='row mb-2 mt-auto'>
                        <div className='col-lg-5'>
                            <h6 className={styles.e_content}>Supplier</h6>
                            <img src={props.data.supplier_image} alt="" width="50px" />
                        </div>
                        <div className='col-lg-7'>
                            <h6 className={styles.e_content}>Filled</h6>
                            <div className={`${styles.e_progress} progress e-progress`} >
                                <div className={`${styles.e_progress_bar} progress-bar e-progress-bar `} style={{ width: `${props.data.filled}`, height: '20px' }} role="progressbar" aria-valuenow="20" aria-valuemin="0" aria-valuemax="100">
                                    <span className='text-end pe-2'>{props.data.filled}</span>
                                </div>

                            </div>
                        </div>
                    </div>
                    {
                        props.fromPublish ?
                            <>

                                <div className='col-lg-12 mt-3'>
                                    <PrimaryBtn
                                        name="View details"
                                        // image={AddToBasket}
                                        // left={true}
                                        iconClass="me-3"
                                        className={styles.e_add_btn}
                                        handleClick={() => {
                                            navigate("/commercial-detail?id=" + props.data.id)
                                        }}
                                    />

                                </div>

                                <div className='col-lg-12 mt-3 text-center'>
                                    <PrimaryLink
                                        name="Unpublish"
                                        className={styles.e_unpublish}
                                        handleClick={() => {

                                        }} />
                                </div>
                            </>
                            :
                            <>
                                {
                                    added ?
                                        <PublishBtn className="mt-3 w-100" />
                                        :
                                        <div className='col-lg-12 mt-3'>
                                            <PrimaryBtn
                                                name="I'm interested"

                                                left={true}
                                                iconClass="me-3"
                                                className={styles.e_add_btn}

                                                handleClick={() => {
                                                    // addToBasket()
                                                }}
                                            />

                                        </div>
                                }
                                <div className='col-lg-12 mt-3 text-center'>
                                    <PrimaryLink
                                        name="View details"
                                        handleClick={() => {
                                            navigate("/lease-detail?id=" + props.data.id)
                                        }}
                                        className={styles.e_view} />
                                </div>
                            </>
                    }

                </div>
            }


            {/* {
                error && (
                    <Toast data={error} />
                )
            } */}

        </>
    )
}

export default LeaseCard;
